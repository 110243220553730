import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  IconButton,
  Typography,
  TextField,
  Button,
  Link,
  SnackbarContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import { firestore } from "../Firebase";
import ReCAPTCHA from "react-google-recaptcha";

/* eslint-disable react/prop-types */
export default function SubscribeModal(props) {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [capVal, setCapVal] = useState(null);
  const [showCap, setShowCap] = useState(true);

  useEffect(() => {
    setMessage(props.message);
  }, [props.message]);

  const handleOpen = () => {
    setOpen(true);
    if (capVal) {
      setShowCap(false);
    } else setShowCap(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const emailSubRef = firestore
        .collection("email-subs")
        .where("email", "==", email);

      emailSubRef.get().then(async (snapshot) => {
        if (snapshot.empty) {
          await firestore
            .collection("email-subs")
            .add({
              email: email,
              is_subscribed: true,
            })
            .then(() => {
              handleSnackbarOpen();
              setEmail("");
            });
        } else {
          firestore.collection("email-subs").doc(snapshot.docs[0].id).update({
            is_subscribed: true,
          });
          handleSnackbarOpen();
          setEmail("");
        }
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSnackbarOpen = () => {
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const subscribeModalBody = (
    <Box
      maxHeight={showCap ? "400px" : "320px"}
      sx={{
        overflow: "auto",
        position: "absolute",
        margin: "auto",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        maxWidth: { xs: "100%", md: "450px" },
        bgcolor: "background.paper",
        boxShadow: 24,
        padding: 4,
        borderRadius: "20px",
      }}
    >
      <Box
        style={{
          flex: "0 0 50%",
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "1dvh",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ color: "black" }}
        >
          <CloseIcon></CloseIcon>
        </IconButton>
      </Box>

      <form onSubmit={handleSubmit}>
        <Box
          display="flex"
          alignContent="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography textAlign="center" fontSize="1.2em">
            Subscribe for weekly reminders to serve
          </Typography>
          <Typography
            marginBottom={4}
            textAlign="center"
            fontSize="1em"
            fontStyle="italic"
          >
            Sent to your inbox every Monday morning
          </Typography>
          <TextField
            label="Email"
            required
            variant="outlined"
            color="secondary"
            type="email"
            sx={{ mb: 2 }}
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <center>
            <Box sx={{ mb: 2 }} display={showCap ? "block" : "none"}>
              <ReCAPTCHA
                sitekey={"6LevT94qAAAAAMLVtbZDrCgnYKfewO3Pdxi_KFd8"}
                onChange={(val) => setCapVal(val)}
              />
            </Box>
          </center>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading || !capVal}
          >
            Subscribe
          </Button>
        </Box>
      </form>
    </Box>
  );

  return (
    <>
      {!message && (
        <>
          <Button
            size="large"
            color="inherit"
            aria-label="subscribe-icon"
            onClick={handleOpen}
            sx={{
              borderRadius: "inherit",
              width: "inherit",
              border: "1px solid white",
              fontSize: { xs: "0.8em", md: "1em" },
              height: "35px",
              padding: { xs: "10px", md: "15px" },
              marginTop: "6px",
              marginLeft: "7px",
            }}
          >
            Subscribe
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="subscribe-modal-title"
            aria-describedby="subscribe-modal-description"
          >
            {subscribeModalBody}
          </Modal>
        </>
      )}
      {message && (
        <>
          <Link
            onClick={handleOpen}
            sx={{
              cursor: "pointer",
              fontSize: "1em",
            }}
          >
            {message}
          </Link>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="subscribe-modal-title"
            aria-describedby="subscribe-modal-description"
          >
            {subscribeModalBody}
          </Modal>
        </>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <SnackbarContent
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
          message={"Successfully subscribed " + email}
        />
      </Snackbar>
    </>
  );
}
